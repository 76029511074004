<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="isCreated ? '新增' : '编辑'"
    :visible.sync="show"
    width="500px"
    class="add-account"
    v-loading="loading"
  >
    <el-form
      size="small"
      :model="params"
      ref="form"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item label="项目名称" prop="name">
        <el-input
          v-model="params.name"
          clearable
          placeholder="请输入"
          maxlength="10"
        ></el-input>
      </el-form-item>

      <el-form-item prop="remark" label="备注">
        <el-input
          v-model="params.remark"
          type="textarea"
          placeholder="请输入备注"
        />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确定</el-button>
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    const rules = {
      name: [{ required: true, message: '请输入项目名称' }],
      remark: [{ required: true, message: '请输入备注' }]
    }

    return {
      loading: false,
      rules,
      params: {}
    }
  },
  created() {
    if (!this.isCreated) {
      this.params = { ...this.currentItem }
    }
  },

  methods: {
    async submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            if (this.isCreated) {
              await this.$api.workOrder.addProject(this.params)
            } else {
              await this.$api.workOrder.editProject(this.params)
            }

            this.$message.success('操作成功')
            this.$emit('updateList')
            this.show = false
          } catch (err) {
            console.log(err)
          } finally {
            this.loading = false
          }
        }
      })
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped></style>
